import React from 'react';
import Home from "./../routes/home";

function App() {

  return (

      <div className="App">
        <Home />
      </div>
 
  );
}

export default App;
