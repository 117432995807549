import logo from './../assets/images/sanofi_logo_minimalist.svg';
import change from './../assets/images/ico_change_user.svg';
import User from './../components/User';
import './../assets/scss/login.scss';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import AuthContext from '../context/auth/authContext';

function Login(props) {
    const authContext = useContext(AuthContext);
    const { login, connected, msg } = authContext;

    let navigate = useNavigate();

    useEffect(() => {
        if (connected) {
            navigate('/dashboard')
        }
    }, [connected])


    const [user, setUser] = useState({
        username: '',
        password: ''
    });

    const { user_id, password } = user;
    const onChange = e => setUser({ ...user, [e.target.name]: e.target.value });
    const onSubmit = e => {
        e.preventDefault();
        //console.log('login submit');
        login({
            user_id,
            password
        })
    };

    //console.log(msg);
    return (
        <div className="login-page">
            <form onSubmit={onSubmit}>
                <div className="login">
                    <div className="head">
                        <img src={logo} alt="Sanofi" className="logo_min" />
                        <h1>Connexion</h1>
                    </div>
                    <div className='body'>
                        <input type='text' name='user_id' value={user_id} placeholder='ID Windows' onChange={onChange} required></input><br></br>
                        <input type='password' name='password' value={password} placeholder='PASSWORD' onChange={onChange} required></input>
                    </div>
                    {msg &&
                    <div className='err'>
                        <p>{msg}</p>
                    </div>
                    }
                    <div className="foot">
                        <button type='submit' className="btn">
                            Se connecter
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Login