import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import AuthContext from '../context/auth/authContext';

const IdleTimeOutHandler = (props) => {
    const authContext = useContext(AuthContext);
    const {logout} = authContext;

    let navigate = useNavigate();

    const[isLogout,setLogout]=useState(false)
    let timer=undefined;
    const events= ['click','scroll','load','keydown']
    const eventHandler =(eventType)=>{
        //console.log(eventType)
        if(!isLogout){
            localStorage.setItem('lastInteractionTime',moment() )
            if(timer){
                props.onActive();
                startTimer();
            }
        }
    };
    useEffect(()=>{
        addEvents();
        return (()=>{
            removeEvents();
            clearTimeout(timer);
        })
    },[])

    const startTimer=()=>{

        if(timer){
            clearTimeout(timer)
        }
        timer=setTimeout(()=>{
            
            let lastInteractionTime=localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let timeOutInterval=props.timeOutInterval?props.timeOutInterval:600000;
            
            if(isLogout){
                clearTimeout(timer)
            } else {
                if(diff._milliseconds<timeOutInterval){
                    startTimer();
                    props.onActive();
                }else{
                    //console.log('event deconnection');
                    props.onIdle();
                    removeEvents();
                    clearTimeout(timer);
                    setLogout(true);
                    props.onLogout();
                    logout();
                    navigate('/login');
                }
            }
        },props.timeOutInterval?props.timeOutInterval:600000)
        
        
    }
    const addEvents=()=>{
        
        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })
        startTimer();
    }
    const removeEvents=()=>{
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };
    
    return(
        <>
        </>
        )
        
}
    
export default IdleTimeOutHandler;