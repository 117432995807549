import React from 'react';

const ChiffreCle = ({ props, isLoading }) => {
    
    return (
        <div className="chiffre">
            <div className="ico">
                {props.icon}
            </div>
            <p className="serif">Chiffre d’affaires <strong>{props.type}</strong></p>
            <h3 className={`${isLoading ? "bars" : ""}`}><span>{props.chiffre}</span></h3>            
            <p>{props.taux}</p>
        </div>
    );
};

export default ChiffreCle