import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../data/colorsData';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const BarChart = ({exercice, labels, pays, taux, cas}) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 20
                }
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Chiffre d\'affaire',
                    align: 'end',
                    font: {
                        size: 12
                    }
                },
            }
        }
    };

    const data = {
        labels,
        datasets: [
            {
                label: exercice[0],
                data: cas[0],
                backgroundColor: colors[0],
            },
            {
                label: exercice[1],
                data: cas[1],
                backgroundColor: colors[1],
            },
            {
                label: exercice[2],
                data: cas[2],
                backgroundColor: colors[2],
            },
            {
                label: exercice[3],
                data: cas[3],
                backgroundColor: colors[3],
            },
            {
                label: exercice[4],
                data: cas[4],
                backgroundColor: colors[4],
            },
        ],
    };
    return <>
        <h3><span>Global</span> Sales YTD 10 A21</h3>
        <Bar options={options} data={data} />
    </>;
}

export default BarChart;