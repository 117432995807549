import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({label, colors, cas}) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                titles: {
                    color: 'rgb(255, 99, 132)'
                }
    
            },
            title: {
                display: false,
                text: 'SPC Sales YTD 10 A21',
                font: {
                    size: 22,
                },
                color: 'black',
    
            },
        },
    };
    const data = {
        labels: [...label],
        datasets: [
            {
                label: '# of Votes',
                data: [...cas],
                backgroundColor: [...colors],
                borderColor: ['#fff'],
                borderWidth: 0.5,
            },
        ],
    };
    return <>
        <h3><span>GenMed</span> Sales YTD 10 A21</h3>
        <Pie data={data} options={options} />
    </>;
}

export default PieChart;