import React from "react";
//import { useMsal } from "@azure/msal-react";
//import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";


export const SignInButton = (props) => {
  function handleLogin() {
    props.navigate("/login");
  }

  return (
    <Button
      variant="secondary"
      className="ml-auto"
      onClick={() => handleLogin()}>
      Se connecter
    </Button>
  );
};
