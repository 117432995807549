import Header from '../components/Header';
import SlideMenu from '../components/SlideMenu';
import Footer from '../components/Footer';
//import Filtre from '../components/Filtre';
import ButtonC from '../components/ButtonC';
import '../assets/scss/donnees.scss';
import React, { useState, useEffect, useContext } from 'react';
//import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { utils, writeFileXLSX } from 'xlsx';

import {
    //BrowserRouter as Router,
    //Route,
    //Switch,
    //Link,
    Navigate,
    useLocation,
    //useParams,
    //useNavigate
} from 'react-router-dom';

import filtreTaux from '../data/filtreTauxData';
import DynamicFiltreTaux from '../components/DynamicFiltreTaux';
import filtreMois from '../data/filtreMoisData';
import { fetchCaFamilyTypeData } from '../api/index';

import AuthContext from '../context/auth/authContext';
//import DynamicFiltre from '../components/DynamicFiltre';

const DataTableFamily = (props) => {

    const authContext = useContext(AuthContext)
    const { connected } = authContext;
    //let params = useParams();
    let location = useLocation();
    const { mois_start, currentMonth_2, currentYear_2, currentTxDefault_2, gbu_2, canal, family, product, currentMonthTitle_2, startMoiTitle, labels, prevPage  } = location.state;
    //console.log(location)

    const [dataDetail, setDataDetail] = useState([[], [], [], [], []]);
    const [isLoading_data, setIsLoading_data] = useState(true);

    const listMois = filtreMois[0].children;


    //SIDEBAR STATE
    const [subnav, setSubnav] = useState(true);
   // const showSubnav = () =>{setSubnav(!subnav) };
    const [slidemenu, setSlideMenu] = useState(false);
    const showSlideMenu = () => { setSlideMenu(!slidemenu); setSubnav(!subnav); };

    const [currentTx_2, setCurrentTx_2] = useState(currentTxDefault_2);
    let showTxa = { 'KDZD': 'K. DZD', 'A': 'K. € Taux A' + String(currentYear_2 - 1).slice(-2), 'B': 'K. € Taux B' + String(currentYear_2).slice(-2) }


    useEffect(() => {
        const ALL_BU_TYPE_DATA = async () => fetchCaFamilyTypeData(mois_start, currentMonth_2, currentYear_2, currentTx_2, gbu_2, canal, family, product );
        ALL_BU_TYPE_DATA().then(
            function (value) {
                //console.log('value : ',value);
                setDataDetail(value);
                setIsLoading_data(false);
            }
        )
    }, [currentYear_2, currentMonth_2, currentTx_2, gbu_2, mois_start, canal, family, product]);

    const handleFiltreTaux_2 = (item) => {
        //console.log('Taux:', item.nom);
        let txabyValue = { 'K. DZD': 'KDZD', 'K. € Taux A': 'A', 'K. € Taux B': 'B' };
        setCurrentTx_2(txabyValue[item.nom]);
    }

    const downloadExcel = () => {
        const title_chart = document.getElementById('title_chart');

        var title_chart_text = title_chart.innerHTML.replace(/<[^>]+>/g, '');
        if (canal !== 'Canal')
            title_chart_text+= ' - ' + canal;
        if (typeof(document.getElementById('family')) != 'undefined' && document.getElementById('family') != null) {
            const family_select = document.getElementById('family').querySelector('p').innerHTML;
            if (family_select !== 'Famille')
                title_chart_text+= ' - ' + family_select;
        }
        if (typeof(document.getElementById('product')) != 'undefined' && document.getElementById('product') != null) {
            const product_select = document.getElementById('product').querySelector('p').innerHTML;
            if (product_select !== 'Produit')
                title_chart_text+= ' - ' + product_select;
        }

        const ALL_BU_TYPE_DATA = async () => fetchCaFamilyTypeData(mois_start, currentMonth_2, currentYear_2, currentTx_2, gbu_2, canal, family, product );
        ALL_BU_TYPE_DATA().then(
            function (value) {

                const row_title = [ "Total CA " + String(currentYear_2) + " (A " + String(currentYear_2).slice(-2) + ")",
                                    "Total CA " + String(currentYear_2 -1) + " (A " + String(currentYear_2 -1).slice(-2) + ")",
                                    "B " + String(currentYear_2).slice(-2),
                                    "F1." + String(currentYear_2).slice(-2),
                                    "F2." + String(currentYear_2).slice(-2),
                                ];
                const col = [];
                col.push("");

                listMois.slice(mois_start - 1, currentMonth_2).map((item) => {
                    return col.push(item.nom);
                })

                const filtres = [
                    ["Année : ", currentYear_2],
                    ["Mois : ", currentMonth_2],
                    ["Exercice : ", mois_start],
                    ["Taux : ", currentTx_2],
                    ["GBU : ", gbu_2],
                    ["Canal : ", canal],
                    ["Famille : ", family],
                    ["Produit : ", product],
                ];

                const filtres_table = [];
                filtres.forEach(function(filtre) {
                    const table = [];
                    table.push(filtre[0]);
                    table.push(filtre[1])
                    for (var i=2; i<col.length; i++){
                        table.push("");
                    }
                    filtres_table.push(table);
                });

                const empty_line = [];
                for (var i=0; i<col.length; i++){
                    empty_line.push("");
                }

                filtres_table.push(empty_line);
                filtres_table.push(col);

                for (var i=0; i<value.length; i++){
                    value[i].unshift(row_title[i]);
                }
                const tableExcel = filtres_table.concat(value);

                //console.log(tableExcel);

                var wb = utils.book_new(),
                    //wss = utils.aoa_to_sheet(filtres),
                    ws = utils.aoa_to_sheet(tableExcel);
                
                //utils.book_append_sheet(wb, wss, "MySheet1");
                utils.book_append_sheet(wb, ws, "MySheet1");
                writeFileXLSX(wb, title_chart_text + ".xlsx");
            }
        )
    };

    return (
        (connected ?
            <div className="dashboard-page">
                <Header />
                <div className="content">
                    <SlideMenu showSlideMenu={showSlideMenu} slidemenu={slidemenu} subnav={subnav} showSubnav={true} />
                    <div className={slidemenu ? 'corp sub-reduced' : 'corp'}>
                        <section className="bloc" >
                            <div className="row">
                                <div className="title">

                                    <h2 id={"title_chart"}>Données graphe “<strong className="secondary"><span>{gbu_2}</span> Sales {(mois_start === 1 && currentMonthTitle_2 !== 'Janvier') ? "YTD (" + currentMonthTitle_2 + ')' : (startMoiTitle.nom === currentMonthTitle_2) ? '(' + currentMonthTitle_2 + ')' : '(' + startMoiTitle.nom + ' - ' + currentMonthTitle_2 + ')'} {labels[0]}</strong>”  ({showTxa[currentTx_2]})</h2>
                                </div>
                                <div className="filtres">

                                    {
                                        filtreTaux.map((item, index) => {
                                            return <DynamicFiltreTaux item={item} key={index} currentYear={currentYear_2} defaultValue={currentTx_2} handleFilterClick={handleFiltreTaux_2} />;
                                        })
                                    }
                                    <div className="export-btn" onClick={(e) => {downloadExcel(mois_start, currentMonth_2, currentYear_2, currentTx_2, gbu_2, canal, family, product, currentMonthTitle_2, startMoiTitle, labels); }}>
                                        <strong className="ico">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21.015" height="20.047" viewBox="0 0 21.015 20.047"><path d="M78.823,14.078a.691.691,0,0,1,.691.691v2.489a4.009,4.009,0,0,1-4.009,4.009h-13A4.009,4.009,0,0,1,58.5,17.257V14.769a.691.691,0,0,1,1.383,0v2.489a2.627,2.627,0,0,0,2.627,2.627h13a2.627,2.627,0,0,0,2.627-2.627V14.769a.691.691,0,0,1,.691-.691ZM69.007,1.22a.691.691,0,0,1,.69.646l0,.045v11.4l2.985-2.785a.691.691,0,0,1,.942,0l.035.035a.691.691,0,0,1,0,.942l-.035.035-4.143,3.867-.039.034-.011.009-.025.019-.018.012-.018.012-.018.011-.028.015-.011.006-.026.012-.018.008-.023.009-.02.007-.018.006-.025.007a.661.661,0,0,1-.069.015l-.018,0-.046,0h-.046a.7.7,0,0,1-.105-.008l-.033-.006a.69.69,0,0,1-.61-.172l-4.148-3.871a.691.691,0,1,1,.943-1.011l3.261,3.044V1.911a.691.691,0,0,1,.691-.691Z" transform="translate(-58.5 -1.22)" /></svg>
                                        </strong>
                                        <p>Télécharger Excel</p>
                                        { /* <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button op0"
                                        table="table-to-xls"
                                        sheet= "Sheet"
                                        filename= {gbu_2 + " Sales YTD (" + currentMonthTitle_2 + ") " + labels[0] + " (" +  showTxa[currentTx_2] + ")" }
                                        buttonText="Download as XLS"/>
                                        */ }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="data-table">
                            <div className={`${isLoading_data ? "show-loader" : "hide-loader"}`}><div className='bars-2'></div></div>
                            <table id="table-to-xls">
                                <thead>
                                    <tr>
                                        <td></td>
                                        {
                                            listMois.slice(mois_start - 1, currentMonth_2).map((item, index) => {
                                                return <th scope="col" key={index}>{item.nom}</th>;
                                            })
                                        }


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Total CA {String(currentYear_2)} (A {String(currentYear_2).slice(-2)})</th>
                                        {dataDetail[0].map((item, index) => {
                                            return <td key={index} >{parseFloat(item.toFixed(2)).toLocaleString('fr')}</td>
                                        })}
                                    </tr>

                                    <tr>
                                        <th scope="row">Total CA {String(currentYear_2 - 1)} (A {String(currentYear_2 - 1).slice(-2)})</th>
                                        {dataDetail[1].map((item, index) => {
                                            return <td key={index} >{parseFloat(item.toFixed(2)).toLocaleString('fr')}</td>
                                        })}
                                    </tr>
                                    <tr>
                                        <th scope="row">B {String(currentYear_2).slice(-2)}</th>
                                        {dataDetail[2].map((item, index) => {
                                            return <td key={index} >{parseFloat(item.toFixed(2)).toLocaleString('fr')}</td>
                                        })}
                                    </tr>
                                    <tr>
                                        <th scope="row">F1.{String(currentYear_2).slice(-2)}</th>
                                        {dataDetail[3].map((item, index) => {
                                            return <td key={index} >{parseFloat(item.toFixed(2)).toLocaleString('fr')}</td>
                                        })}
                                    </tr>
                                    <tr>
                                        <th scope="row">F2.{String(currentYear_2).slice(-2)}</th>
                                        {dataDetail[4].map((item, index) => {
                                            return <td key={index} >{parseFloat(item.toFixed(2)).toLocaleString('fr')}</td>
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="center">
                            <ButtonC children="Retour à la vue précédente" class="btn2" arrow={true} to={prevPage} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            : <Navigate to="/login" />)
    );
}

export default DataTableFamily;