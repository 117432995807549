const filtrePays = [
    {
        id: 2,
        ico: <svg xmlns="http://www.w3.org/2000/svg" width="16.609" height="17.866" viewBox="0 0 16.609 17.866"><g transform="translate(-168.002 -81.221)"><path d="M184.276,84.018l-7.8-2.768a.5.5,0,0,0-.672.473v1.658l-7.466,2.65A.5.5,0,0,0,168,86.5V98.584a.5.5,0,0,0,.5.5h15.6a.5.5,0,0,0,.5-.5V84.491a.5.5,0,0,0-.335-.473Zm-15.268,2.841,6.795-2.411V98.08h-6.795Zm9.94,10.97V92.8h2.517v5.033Zm4.656.252h-1.133V92.293h0a.5.5,0,0,0-.5-.5h-3.523a.5.5,0,0,0-.5.5v5.788h-1.132V82.435l6.795,2.411Z" transform="translate(0)" nom /><path d="M395.3,255.809h3.02a.5.5,0,1,0,0-1.007H395.3a.5.5,0,0,0,0,1.007Z" transform="translate(-216.607 -165.781)" nom /><path d="M395.3,194.2h3.02a.5.5,0,0,0,0-1.007H395.3a.5.5,0,0,0,0,1.007Z" transform="translate(-216.607 -106.945)" nom /><path d="M224.721,316.4H221.7a.5.5,0,0,0,0,1.007h3.02a.5.5,0,0,0,0-1.007Z" transform="translate(-50.806 -224.611)" nom /><path d="M224.721,254.8H221.7a.5.5,0,1,0,0,1.007h3.02a.5.5,0,1,0,0-1.007Z" transform="translate(-50.806 -165.779)" nom /><path d="M224.721,372.4H221.7a.5.5,0,0,0,0,1.007h3.02a.5.5,0,0,0,0-1.007Z" transform="translate(-50.806 -278.094)" nom /></g></svg>,
        placeHolder: 'GBU',
        children: [
            {
                id: 1,
                nom: "Global"
            },
            {
                id: 2,
                nom: "GenMed",
                children: [
                    {
                        id: 2_1,
                        nom: "Foundation"
                    },
                    {
                        id: 2_2,
                        nom: "Core"
                    },
                ]
            },
            {
                id: 3,
                nom: "SPC"
            },
            {
                id: 4,
                nom: "CHC"
            },

        ]
    },
];

export default filtrePays