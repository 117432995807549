const filtreExercice = [
    {
        id: 1,
        ico: <svg xmlns="http://www.w3.org/2000/svg" width="17.264" height="18.658" viewBox="0 0 17.264 18.658"><g transform="translate(-399.625 -691.818)"><path d="M-.375-.375H16.889V16H-.375Zm16.514.75H.375V15.254H16.139Z" transform="translate(400 694.472)" nom/><path d="M.375,5.433h-.75V0h.75Z" transform="translate(412.975 691.818)" nom/><path d="M.375,5.433h-.75V0h.75Z" transform="translate(403.834 691.818)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(401.769 701.845)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(405.505 701.845)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(409.24 701.845)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(412.975 701.845)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(401.769 704.794)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(405.505 704.794)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(409.24 704.794)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(412.975 704.794)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(401.769 707.448)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(405.505 707.448)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(409.24 707.448)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(412.975 707.448)" nom/><path d="M16.514.375H0v-.75H16.514Z" transform="translate(400 699.191)" /></g></svg>,
        placeHolder: 'Exercice',
        children: [
            { value: 'A20', label: 'A20' },
            { value: 'B21', label: 'B21' },
            { value: 'F1.21', label: 'F1.21' },
            { value: 'F2.21', label: 'F2.21' },
              
        ]
    },
];

export default filtreExercice