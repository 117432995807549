import axios from 'axios';
//let apiURL = 'http://localhost:8088/api';
let apiURL = 'http://149.202.4.10:49160/api';
//let apiURL = 'http://10.27.64.18:8088/api';



export const fetchCaGeneral = async (mois_start, mois, year, tx) => {
    try {
        const { data } = await axios.get(`${apiURL}/ca-general?mois_start=${mois_start}&mois=${mois}&year=${year}&tx=${tx}`);
        return data;
    } catch (error) {
        return error;
    }
};

export const fetchCaGeneralType = async (mois_start, mois, year, tx, gbu, canal) => {
    try {
        const { data } = await axios.get(`${apiURL}/ca-general-type?mois_start=${mois_start}&mois=${mois}&year=${year}&tx=${tx}&gbu=${gbu}&canal=${canal}`);
        return data;
    } catch (error) {
        return error;
    }
};
export const fetchCaGeneralTypeData = async (mois_start, mois, year, tx, gbu, canal) => {
    try {
        const { data } = await axios.get(`${apiURL}/ca-general-type-data?mois_start=${mois_start}&mois=${mois}&year=${year}&tx=${tx}&gbu=${gbu}&canal=${canal}`);
        return data;
    } catch (error) {
        return error;
    }
};

export const fetchGetFamiliesByBu = async (bu, canal) => {
    try {
        const { data } = await axios.get(`${apiURL}/get-families?bu=${bu}&canal=${canal}`);
        return data;
    } catch (error) {
        return error;
    }
};

export const fetchGetProductsByFamily = async (bu, canal, family) => {
    try {
        const { data } = await axios.get(`${apiURL}/get-products?bu=${bu}&canal=${canal}&family=${family}`);
        return data;
    } catch (error) {
        return error;
    }
};

export const fetchGetCaByFamily = async (mois_start, mois, year, tx, gbu, canal, family, product) => {
    try {
        const { data } = await axios.get(`${apiURL}/ca-by-family?mois_start=${mois_start}&mois=${mois}&year=${year}&tx=${tx}&gbu=${gbu}&canal=${canal}&family=${family}&product=${product}`);
        return data;
    } catch (error) {
        return error;
    }
};

export const fetchCaFamilyTypeData = async (mois_start, mois, year, tx, gbu, canal, family, product) => {
    try {
        const { data } = await axios.get(`${apiURL}/ca-Family-type-data?mois_start=${mois_start}&mois=${mois}&year=${year}&tx=${tx}&gbu=${gbu}&canal=${canal}&family=${family}&product=${product}`);
        return data;
    } catch (error) {
        return error;
    }
};
/****/
export const fetchCaBu = async (bu, mois, year, tx) => {
    try {
        const { data } = await axios.get(`${apiURL}/api/ca-bu?bu=${bu}&mois=${mois}&year=${year}&tx=${tx}`);
        return data;
    } catch (error) {
        return error;
    }
};


