import React from 'react';
import logo_min from './../assets/images/sanofi_logo_minimalist.svg';

function Footer() {
    return (
        <footer className="footer">
            <div className="content">
                <p>Données strictement confidentielles / © Tous droits réservés - Sanofi Aventis Algérie </p>
                <img src={logo_min} alt="sanofi" />
            </div>
        </footer>
    );
}

export default Footer