import React from 'react';
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip
  } from "chart.js";
  import annotationPlugin from "chartjs-plugin-annotation";
  import { useEffect, useRef, useState } from "react";
  
  Chart.register(
    CategoryScale,
    LinearScale,
    LineController,
    BarController,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    annotationPlugin
  );
  
  export default function BarChartGap() {
    const canvas = useRef(null);
    const [chart, setChart] = useState();
  
    useEffect(() => {
      if (chart || !canvas.current) return;
      const ctx = canvas.current.getContext("2d");
      if (!ctx) return;
  
      const config = {
        responsive: true,
        type: "bar",
        data: {
          labels: ["aeeddd", "b", "c"],
          datasets: [
            {
              type: "bar",
              data: [1, 2, 5],
              click: function ({ chart, element }) {
                //console.log("Box annotation baaaaar");
              },
            }
            
          ]
        },
        options: {
          animation: {
            duration: 100,
            easing: "easeOutExpo"
          },
          plugins: {
            annotation: {
              annotations: {
                test: {
                  type: "line",
                  scaleID: "y",
                  value: 2,
                  label: {
                    content: "test",
                    enabled: true
                  }
                },
                annotation1: {
                  type: "box",
                  backgroundColor: "rgba(101, 33, 171, 0.5)",
                  borderColor: "rgb(101, 33, 171)",
                  borderWidth: 1,
                  click: function ({ chart, element }) {
                    //console.log("Box annotation clicked");
                  },
                  drawTime: "beforeDatasetsDraw",
                  xMax: -0.5,
                  xMin: 0.5,
                  xScaleID: "x",
                  yMax: 2,
                  yMin: 3,
                  yScaleID: "y"
                },
                
              }
            }
          },
        }
      };
  
      setChart(new Chart(ctx, config));
    }, [chart, canvas]);
  
    return (
      <div>
        <canvas ref={canvas} />
      </div>
    );
  }
  