import React, { useReducer } from "react";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import axios from 'axios';
import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS
} from '../types';

//let apiURL = 'http://localhost:8088/api';
let apiURL = 'http://149.202.4.10:49160/api';
//let apiURL = 'http://10.27.64.18:8088/api';

const AuthState = props => {
    const initialState = {
        //token: localStorage.getItem('token'),
        msg: null,
        connected: false,
        username: '',
        name: ''
    };

    const [state, dispatch] = useReducer(authReducer, initialState);

    // Login User 
    const login = async formData => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const res = await axios.post(`${apiURL}/login`, formData, config);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });
            //console.log(res.data)
        } catch (err) {
            //console.log(state);
            dispatch({
                type: LOGIN_FAIL,
                payload: err.response.data
            });
            //console.log(err.response.data);
        }
    }

    // Logout 
    const logout = () => dispatch({
        type: LOGOUT
    });

    return (
            <AuthContext.Provider
                value={{
                    //msg: state.msg,
                    //msg: localStorage.getItem("msg"),
                    //connected: localStorage.getItem("connected"),
                    connected: true,
                    //username: localStorage.getItem("username"),
                    username: 'SHIFTIN',
                    //name: localStorage.getItem("name"),
                    name: 'DEMO',
                    login: login,
                    logout: logout,
                    
                }}
            >
                {props.children}
            </AuthContext.Provider>
    );
};

export default AuthState;