import React, { useState, useEffect, useRef } from 'react';

import avatar from './../assets/images/user.svg';
import menu from './../assets/images/ico_menu.svg';
//import { Link } from 'react-router-dom';

function UserMenu(props) {
    const [usermenu, setUserMenu] = useState(false);
    const showUserMenu = () => setUserMenu(!usermenu);

    const componentRef = useRef();

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e) {
            if(componentRef && componentRef.current){
                const ref = componentRef.current
                if(!ref.contains(e.target)){
                    setUserMenu();
                }
            }
        }
    }, []);

    return (
        <div className="user_menu" ref={componentRef}>
            <button onClick={showUserMenu} className="user">
                <strong className="sep"></strong>
                {props.name.toUpperCase()}
                <strong className="ico" >
                    <img src={avatar} alt="avatar" /> 
                </strong>
                <div className="arrow_menu">
                    <img src={menu} alt="menu" />
                </div>
            </button>
            <div className={usermenu ? 'submenu disp' : 'submenu' }>
                <button className='cursor-pointer' onClick={() => props.handleLogout()} >Se déconnecter</button>
            </div>
        </div>
    );
}

export default UserMenu