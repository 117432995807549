import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../data/colorsData';
import filtreMois from '../data/filtreMoisData';
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

const BarChartGeneral = ({ exercice, mois_start, labels, gbu_2, taux, cas, gabs, isLoading, currentMonthTitle_2 }) => {
    const listMois = filtreMois[0].children;
    let startMoiTitle = listMois.find(obj => {
        return obj.default === mois_start;
    });
    //  console.log('cas in chart', cas)
    //const [annotation_A_1_LABEL_STATE, setannotation_A_1_LABEL_STATE] = useState(true);
    //const [annotation_B_LABEL_STATE, setannotation_B_LABEL_STATE] = useState(true);
    //const [annotation_F1_LABEL_STATE, setannotation_F1_LABEL_STATE] = useState(true);
    //const [annotation_F2_LABEL_STATE, setannotation_F2_LABEL_STATE] = useState(true);

    const annotation_A_1 = {
        type: 'box',
        display: (gabs.Default_A_1[0] > 0 && cas[1] > 0),
        backgroundColor: gabs.Default_A_1[2],
        borderColor: gabs.Default_A_1[3],
        borderWidth: 1,
        borderDash: [5, 10],
        drawTime: 'afterDatasetsDraw',
        /*  enter() {
              setannotation_A_1_LABEL_STATE(true);
          },
          leave() {
              setannotation_A_1_LABEL_STATE(false);
          },*/
        xMin: 0.5 + 0.14,
        xMax: 1.5 - 0.14,
        xScaleID: 'x',
        yMin: gabs.Default_A_1[0],
        yMax: gabs.Default_A_1[1],
        yScaleID: 'y'
    };
    const annotation_A_1_LABEL = {
        type: 'label',
        backgroundColor: () => {
            let backgroundColor;
            (gabs.Default_A_1[4] > 0) ? backgroundColor = 'rgba(6, 52, 31,0.8)' :
                backgroundColor = 'rgba(71, 4, 4,0.8)';
            return backgroundColor;
        },
        color: 'white',
        callout: {
            enabled: true,
        },
        display: (gabs.Default_A_1[0] > 0 && cas[1] > 0),
        // display: annotation_A_1_LABEL_STATE,
        drawTime: 'afterDatasetsDraw',
        content: () => {
            let texte;
            let gapToShow;
            (gabs.Default_A_1[0] > 0) ? gapToShow = gabs.Default_A_1[4].toFixed(0) : gapToShow = gabs.Default_A_1[4];
            (gabs.Default_A_1[4] > 0) ? texte = [labels[0] + '  vs ' + labels[1], Math.abs(gapToShow).toLocaleString('fr'), taux, '(' + ((gabs.Default_A_1[4] / gabs.Default_A_1[0]) * 100).toFixed(0) + '%)'] : texte = [labels[0] + '  vs ' + labels[1], Math.abs(gapToShow).toLocaleString('fr'), taux, '(' + Math.abs((gabs.Default_A_1[4] / gabs.Default_A_1[0]) * 100).toFixed(0) + '%)']
            return texte;
        },
        font: {
            size: 10
        },
        position: {
            x: 'center',
            y: 'end'
        },
        yAdjust: -20,
        yValue: Math.abs(gabs.Default_A_1[0]) + (gabs.Default_A_1[4] / 2),
        xValue: labels[1],
    };
    const annotation_B = {
        type: 'box',
        display: (gabs.Default_B[0] > 0 && cas[2] > 0),
        backgroundColor: gabs.Default_B[2],
        borderColor: gabs.Default_B[3],
        borderWidth: 1,
        borderDash: [5, 10],
        drawTime: 'afterDatasetsDraw',
        /*  enter() {
              setannotation_B_LABEL_STATE(true);
          },
          leave() {
              setannotation_B_LABEL_STATE(false);
          },*/
        xMin: 1.5 + 0.14,
        xMax: 2.5 - 0.14,
        xScaleID: 'x',
        yMin: gabs.Default_B[0],
        yMax: gabs.Default_B[1],
        yScaleID: 'y'
    };
    const annotation_B_LABEL = {
        type: 'label',
        backgroundColor: () => {
            let backgroundColor;
            (gabs.Default_B[4] > 0) ? backgroundColor = 'rgba(6, 52, 31,0.8)' :
                backgroundColor = 'rgba(71, 4, 4,0.8)';
            return backgroundColor;
        },
        color: 'white',
        callout: {
            enabled: true,
        },
        display: (gabs.Default_B[0] > 0 && cas[2] > 0),
        content: () => {
            let texte;
            let gapToShow;
            (gabs.Default_B[0] > 0) ? gapToShow = gabs.Default_B[4].toFixed(0) : gapToShow = gabs.Default_B[4];
            (gabs.Default_B[4] > 0) ? texte = [labels[0] + '  vs ' + labels[2], Math.abs(gapToShow).toLocaleString('fr'), taux, '(' + ((gabs.Default_B[4] / gabs.Default_B[0]) * 100).toFixed(0) + '%)'] : texte = [labels[0] + '  vs ' + labels[2], Math.abs(gapToShow).toLocaleString('fr'), taux, '(' + Math.abs((gabs.Default_B[4] / gabs.Default_B[0]) * 100).toFixed(0) + '%)']
            return texte;
        },
        font: {
            size: 10
        },
        position: {
            x: 'center',
            y: 'end'
        },
        yAdjust: -20,
        yValue: Math.abs(gabs.Default_B[0]) + (gabs.Default_B[4] / 2),
        xValue: labels[2],
    };
    const annotation_F1 = {
        type: 'box',
        display: (gabs.Default_F1[0] > 0 && cas[3] > 0),
        backgroundColor: gabs.Default_F1[2],
        borderColor: gabs.Default_F1[3],
        borderWidth: 1,
        borderDash: [5, 10],
        drawTime: 'afterDatasetsDraw',
        /*enter() {
            setannotation_F1_LABEL_STATE(true);
        },
        leave() {
            setannotation_F1_LABEL_STATE(false);
        },*/
        xMin: 2.5 + 0.14,
        xMax: 3.5 - 0.14,
        xScaleID: 'x',
        yMin: gabs.Default_F1[0],
        yMax: gabs.Default_F1[1],
        yScaleID: 'y'
    };
    const annotation_F1_LABEL = {
        type: 'label',
        backgroundColor: () => {
            let backgroundColor;
            (gabs.Default_F1[4] > 0) ? backgroundColor = 'rgba(6, 52, 31,0.8)' :
                backgroundColor = 'rgba(71, 4, 4,0.8)';
            return backgroundColor;
        },
        color: 'white',
        callout: {
            enabled: true,
        },
        // display: annotation_F1_LABEL_STATE,
        display: (gabs.Default_F1[0] > 0 && cas[3] > 0),
        content: () => {
            let texte;
            let gapToShow;
            (gabs.Default_F1[0] > 0) ? gapToShow = gabs.Default_F1[4].toFixed(0) : gapToShow = gabs.Default_F1[4];
            (gabs.Default_F1[4] > 0) ? texte = [labels[0] + '  vs ' + labels[3], Math.abs(gapToShow).toLocaleString('fr'), taux, '(' + ((gabs.Default_F1[4] / gabs.Default_F1[0]) * 100).toFixed(0) + '%)'] : texte = [labels[0] + '  vs ' + labels[3], Math.abs(gapToShow).toLocaleString('fr'), taux, '(' + Math.abs((gabs.Default_F1[4] / gabs.Default_F1[0]) * 100).toFixed(0) + '%)']
            return texte;
        },
        font: {
            size: 10
        },
        position: {
            x: 'center',
            y: 'end'
        },
        yAdjust: -20,
        yValue: Math.abs(gabs.Default_F1[0]) + (gabs.Default_F1[4] / 2),
        xValue: labels[3],
    };
    const annotation_F2 = {
        type: 'box',
        display: (gabs.Default_F2[0] > 0 && cas[4] > 0),
        backgroundColor: gabs.Default_F2[2],
        borderColor: gabs.Default_F2[3],
        borderWidth: 1,
        borderDash: [5, 10],

        drawTime: 'afterDatasetsDraw',
        /* enter() {
             setannotation_F2_LABEL_STATE(true);
         },
         leave() {
             setannotation_F2_LABEL_STATE(false);
         },*/
        xMin: 3.5 + 0.14,
        xMax: 4.5 - 0.14,
        xScaleID: 'x',
        yMin: gabs.Default_F2[0],
        yMax: gabs.Default_F2[1],
        yScaleID: 'y'
    };
    const annotation_F2_LABEL = {
        type: 'label',
        backgroundColor: () => {
            let backgroundColor;
            (gabs.Default_F2[4] > 0) ? backgroundColor = 'rgba(6, 52, 31,0.8)' :
                backgroundColor = 'rgba(71, 4, 4,0.8)';
            return backgroundColor;
        },
        color: 'white',
        callout: {
            enabled: true,
        },
        //display: annotation_F2_LABEL_STATE,
        display: (gabs.Default_F2[0] > 0 && cas[4] > 0),
        content: () => {
            let texte;
            let gapToShow;
            (gabs.Default_F2[0] > 0) ? gapToShow = gabs.Default_F2[4].toFixed(0) : gapToShow = gabs.Default_F2[4];
            (gabs.Default_F2[4] > 0) ? texte = [labels[0] + '  vs ' + labels[4], Math.abs(gapToShow).toLocaleString('fr'), taux, '(' + ((gabs.Default_F2[4] / gabs.Default_F2[0]) * 100).toFixed(0) + '%)'] : texte = [labels[0] + '  vs ' + labels[4], Math.abs(gapToShow).toLocaleString('fr'), taux, '(' + Math.abs((gabs.Default_F2[4] / gabs.Default_F2[0]) * 100).toFixed(0) + '%)']
            return texte;
        },
        font: {
            size: 10
        },
        position: {
            x: 'center',
            y: 'end'
        },
        yAdjust: -20,
        yValue: Math.abs(gabs.Default_F2[0]) + (gabs.Default_F2[4] / 2),
        xValue: labels[4],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'right',
                labels: {
                    boxWidth: 20
                }
            },
            title: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {

                    afterBody: function () {
                        return taux;
                    },

                }
            },
            annotation: {
                annotations: {
                    annotation_A_1,
                    annotation_A_1_LABEL,
                    annotation_B,
                    annotation_B_LABEL,
                    annotation_F1,
                    annotation_F1_LABEL,
                    annotation_F2,
                    annotation_F2_LABEL
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grace: '45%',
                title: {
                    display: true,
                    text: 'Chiffre d\'affaire',
                    align: 'end',
                    font: {
                        size: 12
                    }
                },
            }
        },
        /*tooplips: {
            shadowOffsetX: 10,
            shadowOffsetY: 10,
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.9)'
        },*/
    };

    const bgColor = {
        id: "bgColor",
        beforeDraw: (chart) => {
            const {ctx, width, height} = chart;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, width, height);
            ctx.restore();
        }
    }

    const shadowed = {
        beforeDatasetsDraw: function(chart, options) {
            chart.ctx.shadowColor = 'rgba(0, 0, 0, 0.6)';
            chart.ctx.shadowOffsetX = 2;
            chart.ctx.shadowOffsetY = 6;
            chart.ctx.shadowBlur = 12;
        },
        afterDatasetsDraw: function(chart, options) {
            chart.ctx.shadowColor = 'rgba(0, 0, 0, 0)';
            chart.ctx.shadowBlur = 0;
        }
    };

    const plugins = [bgColor, shadowed];

    const data = {
        //labels: ['A', 'A-1', 'B', 'F1', 'F2'],
        labels: labels,
        datasets: [{
            //label: ['Global Sales YTD 10 A21'],
            data: cas,
            //data: ["10000", "20000", "30000", "40000", "50000"],
            backgroundColor: [
                colors[0],
                colors[1],
                colors[2],
                colors[3],
                colors[4]
            ],
            /*shadowOffsetX: 10,
            shadowOffsetY: 10,
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.9)',*/
        }],

    };


    return <>
        <h3 id={"title_chart"}><span>{gbu_2}</span> Sales {(mois_start === 1 && currentMonthTitle_2 !== 'Janvier') ? 'YTD (' + currentMonthTitle_2 + ')' : (startMoiTitle.nom === currentMonthTitle_2) ? '(' + currentMonthTitle_2 + ')' : '(' + startMoiTitle.nom + ' - ' + currentMonthTitle_2 + ')'} {labels[0]}</h3>
        <div className={`${isLoading ? "show-loader" : "hide-loader"}`}><div className='bars-2'></div></div>
        <Bar id={"chart"} options={options} data={data} plugins={plugins} />

    </>;
}

export default BarChartGeneral;