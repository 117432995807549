import logo from './../assets/images/sanofi_logo.svg';
import UserMenu from './UserMenu';
import { Link, useNavigate } from "react-router-dom";

//import { useIsAuthenticated } from "@azure/msal-react";
//import { SignInButton } from "./SignInButton";
//import { SignOutButton } from "./SignOutButton";

//import { useMsal } from "@azure/msal-react";
//import Button from "react-bootstrap/Button";
import React, { useState, useContext } from 'react';

import AuthContext from '../context/auth/authContext';

import IdleTimeOutHandler from './IdleTimeOutHandler';


function Header() {
    const authContext = useContext(AuthContext);
    const {connected, username, name, logout} = authContext;
    let navigate = useNavigate();
  
    const user = username + ' ' + name;
    function handleLogout() {      
        logout()
        navigate('/login')
    }

    const[isActive,setIsActive]=useState(true);
    const[isLogout,setLogout]=useState(false);
  
    return (
        <>
        <div className="header">
            <div className="content_left">
                <Link to="/dashboard"><img src={logo} alt="Sanofi Logo" className="logo" /></Link>
            </div>
            <div className="content_right">
                <div className="big_title">
                    <h1>Dashboard <strong className="serif secondary">Ventes</strong> et Approvisionnement</h1>
                </div>

                <UserMenu name={user}  handleLogout={handleLogout} />
            </div>
        </div>
        { connected &&
        <IdleTimeOutHandler 
            onActive={()=>{setIsActive(true)}} 
            onIdle={()=>{setIsActive(false)}}
            onLogout={()=>{setLogout(true)}}
            />
        }
        </>
    );
}

export default Header