import React,  { useState, useContext, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './assets/scss/global.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import Login from "./routes/login";
import Dashboard from './routes/dashboard';
import DataTable from './routes/donnees';
import DataTableFamily from './routes/donnees-family';

import CaMensuel from './routes/ca-mensuel';
import CaGbu from './routes/ca-gbu';
import CaGbuMois from './routes/ca-gbu-mois';
import CaFamilyProduct from './routes/ca-family-product';
import CaFamilyProductMois from './routes/ca-family-product-mois';
import CaCanal from './routes/ca-canal';
import CaCanalMois from './routes/ca-canal-mois';
import FiltreTemps from './routes/filtre-temps';
import FiltreGbu from './routes/filtre-gbu';
import FiltreFamilleProduit from './routes/filtre-famille-produit';
import FiltreCanal from './routes/filtre-canal';


import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";


import AuthState from './context/auth/AuthState';




const container = document.getElementById('root');
const root = createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig);


root.render(

  <React.StrictMode>
    <BrowserRouter>
      <AuthState>
       
        <Routes>     
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<App tab="home" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/donnees" element={<DataTable />} />
          <Route path="/donnees-family" element={<DataTableFamily />} />
          <Route path="/ca-mensuel" element={<CaMensuel />} />
          <Route path="/ca-gbu" element={<CaGbu />} />
          <Route path="/ca-gbu-mois" element={<CaGbuMois />} />
          <Route path="/ca-family-product" element={<CaFamilyProduct />} />
          <Route path="/ca-family-product-mois" element={<CaFamilyProductMois />} />
          <Route path="/ca-canal" element={<CaCanal />} />
          <Route path="/ca-canal-mois" element={<CaCanalMois />} />
          <Route path="/filtre-temps" element={<FiltreTemps />} />
          <Route path="/filtre-gbu" element={<FiltreGbu />} />
          <Route path="/filtre-famille-produit" element={<FiltreFamilleProduit />} />
          <Route path="/filtre-canal" element={<FiltreCanal />} />
        </Routes>
      </AuthState>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
