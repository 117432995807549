import { Link } from "react-router-dom";
import React from 'react';

function Button(props) {
    return (
        <Link className={props.class} to={props.to} state={(props.donnees) ? { mois_start: props.mois_start, currentMonth_2: props.currentMonth_2, currentYear_2: props.currentYear_2, currentTxDefault_2: props.currentTx_2, gbu_2: props.gbu_2, canal: props.canal,family: props.family,product: props.product, currentMonthTitle_2: props.currentMonthTitle_2, startMoiTitle: props.startMoiTitle, labels: props.labels, prevPage: props.prevPage  } :  null}>
            {props.children}
            {props.arrow &&
                <strong className="ico" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.69" height="12.26" viewBox="0 0 18.081 7.465"><g transform="translate(-384.5 -733.41)"><path d="M402.4,737.565l-3.3,3.31-.435-.435-.423-.423,2.271-2.271H384.5v-1.208h16.018l-2.271-2.271.423-.423.435-.435,3.3,3.31a.552.552,0,0,1,.133.193A.593.593,0,0,1,402.4,737.565Z" /></g></svg>
                </strong>
            }
        </Link>
    );
}

export default Button