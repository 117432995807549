import React, { useState, useContext, useEffect } from 'react';
import logo from './../assets/images/sanofi_logo.svg';
import key_visual from './../assets/images/key-visual.png';
import dot from './../assets/images/dot.svg';
import Footer from '../components/Footer';
//import Button from  '../components/Button';
import '../assets/scss/global.scss';
import '../assets/scss/home.scss';
import '../assets/scss/slide_menu.scss';


import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from '../components/SignInButton';
import { SignOutButton } from '../components/SignOutButton';
//import Button from "react-bootstrap/Button";
import { Link, useNavigate } from 'react-router-dom';

import AuthContext from '../context/auth/authContext';

function Home() {
    const authContext = useContext(AuthContext)
    let navigate = useNavigate();
    const { connected, username, name, logout } = authContext;
    //const isAuthenticated = true;
    return (
        <div className="home-page">
            <div className="page">
                <div className="elem">
                    <img src={dot} alt="" />
                </div>
                <section className="head">
                    <img src={logo} alt="Sanofi Logo" className="logo" />
                </section>
                <section className="corp">
                    <div className="content">
                        <h2>Bienvenue </h2>
                        <h1>Dashboard <span className="serif secondary">Ventes</span> et Approvisionnement</h1>
                        <p>Votre tableau de bord interactif pour un accès direct à toutes les données de vente.</p>

                        {connected ?
                            <div className='inline-flex'>
                                <a className='btn' href="/dashboard">
                                    <button type="button" >
                                        Accéder au Dashboard
                                    </button>
                                </a>
                                <div><SignOutButton logout={logout} navigate={navigate} /></div>
                            </div> : null}

                        {connected ? null : <SignInButton navigate={navigate} />}
                    </div>
                    <div className="key_visual">
                        <img src={key_visual} alt="Dashboard Sanofi" />
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Home;