import React, { useState, useEffect, useContext } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    Navigate,
    useLocation,
} from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SlideMenu from '../components/SlideMenu';
import ChiffreCle from '../components/ChiffreCle';
import Filtre from '../components/Filtre';
import DynamicFiltre from '../components/DynamicFiltre';
import DynamicFiltreTaux from '../components/DynamicFiltreTaux';
import DynamicFiltreMois from '../components/DynamicFiltreMois';
import FiltreChild from '../components/FiltreChild';
import FiltreCheck from '../components/FiltreCheck';
import FiltreCheckDynamic from '../components/FiltreCheckDynamic';
import BarChartGeneral from '../components/BarChartGeneral';
import BarChartGap from '../components/BarChartGap';
import LineChartGap from '../components/LineChartGap';
import Button from '../components/Button';
import ButtonC from '../components/ButtonC';
import DownloadBtn from '../components/DownloadBtn';
//import Select from 'react-select';
import '../assets/scss/dashboard.scss';

import ChiffreData from '../data/ChiffreData';

import filtreMois from '../data/filtreMoisData';
import filtreAnnee from '../data/filtreAnneeData';
import filtreTaux from '../data/filtreTauxData';
import filtreExercice from '../data/filtreExerciceData';
import filtrePays from '../data/filtrePaysData';
import filtrePaysDetail from '../data/filtrePaysDetailData';



import { fetchCaGeneral, fetchCaGeneralType } from '../api/index';

import AuthContext from '../context/auth/authContext';

const CaGbu = () => {
    const authContext = useContext(AuthContext)
    const { connected } = authContext;
    const location = useLocation();

    let today = new Date();

    let mois_start_default = 1;
    const [mois_start, SetMois_start] = useState(mois_start_default);

    let currentMonthDefault_2 = (today.getMonth() + 1);
    const [currentMonth_2, setCurrentMonth_2] = useState(currentMonthDefault_2);
    let currentYearDefault_2 = today.getFullYear();
    const [currentYear_2, setCurrentYear_2] = useState(currentYearDefault_2);
    let currentTxDefault_2 = 'A';
    const [currentTx_2, setCurrentTx_2] = useState(currentTxDefault_2);
    let showTxa = { 'KDZD': 'K. DZD', 'A': 'K. € Taux A' + String(currentYear_2 - 1).slice(-2), 'B': 'K. € Taux B' + String(currentYear_2).slice(-2) }
    // GABS STATE
    let default_gabs = {
        Default_A_1: [0, 0],
        Default_B: [0, 0],
        Default_F1: [0, 0],
        Default_F2: [0, 0],
    };
    const [gabs, setGabs] = useState(default_gabs);
    // TITLE SECTION 2
    const listMois = filtreMois[0].children;
    let defaultTitle = listMois.find(obj => {
        return obj.default == currentMonthDefault_2;
    });
    const [currentMonthTitle_2, setCurrentMonthTitle_2] = useState(defaultTitle.nom);


    const [exercice, setExercice] = useState(['A22', 'A21', 'B22', 'F1.22', 'F2.22',]);
    const [labels, setLabels] = useState(['A', 'A-1', 'B', 'F1', 'F2',]);
    const [cas, setCas] = useState(['0', '0', '0', '0', '0']);
    const [isLoading_2, setIsLoading_2] = useState(false);
    const [gbu_2, setGbu_2] = useState('Global');

    // CHECKBOX STATE
    const [checkedState, setCheckedState] = useState(
        new Array(labels.length).fill(true)
    );
    const [saveOldItemCasValue, setSaveOldItemCasValue] = useState([]);
    const canal = 'Canal';
    let startMoiTitle = listMois.find(obj => {
        return obj.default == mois_start;
    });
    //let mois_start = currentMonth_2;
    //const [mois_start, SetMois_start] = useState();

    // SECTION EFFECT 2
    useEffect(() => {

        const ALL_BU_TYPE = async () => fetchCaGeneralType(mois_start, currentMonth_2, currentYear_2, currentTx_2, gbu_2, canal);

        setIsLoading_2(true);
        ALL_BU_TYPE().then(
            function (value) {
                let updateGeneralType = value.map(String);

                let GAP_A_1 = value[0] - value[1];
                GAP_A_1 = GAP_A_1 || 0;
                let GAP_B = value[0] - value[2];
                GAP_B = GAP_B || 0;
                let GAP_F1 = value[0] - value[3];
                GAP_F1 = GAP_F1 || 0;
                let GAP_F2 = value[0] - value[4];
                GAP_F2 = GAP_F2 || 0;

                let new_gabs = {
                    Default_A_1: [value[1], value[1] + GAP_A_1, (GAP_A_1 > 0) ? 'rgba(189, 242, 213, 0.5)' : 'rgba(243, 36, 36, 0.8)', (GAP_A_1 > 0) ? 'rgb(189, 242, 213)' : 'rgb(243, 36, 36)', GAP_A_1],
                    Default_B: [value[2], value[2] + GAP_B, (GAP_B > 0) ? 'rgba(189, 242, 213, 0.5)' : 'rgba(243, 36, 36, 0.8)', (GAP_B > 0) ? 'rgb(189, 242, 213)' : 'rgb(243, 36, 36)', GAP_B],
                    Default_F1: [value[3], value[3] + GAP_F1, (GAP_F1 > 0) ? 'rgba(189, 242, 213, 0.5)' : 'rgba(243, 36, 36, 0.8)', (GAP_B > 0) ? 'rgb(189, 242, 213)' : 'rgb(243, 36, 36)', GAP_F1],
                    Default_F2: [value[4], value[4] + GAP_F2, (GAP_F2 > 0) ? 'rgba(189, 242, 213, 0.5)' : 'rgba(243, 36, 36, 0.8)', (GAP_B > 0) ? 'rgb(189, 242, 213)' : 'rgb(243, 36, 36)', GAP_F2],
                };

                setCas([...updateGeneralType]);
                setGabs(new_gabs);
                setCheckedState(new Array(labels.length).fill(true));
                setIsLoading_2(false);
            }
        );
        let currentYear_2_string = String(currentYear_2).slice(-2)
        let currentYear_2_1 = currentYear_2 - 1;
        currentYear_2_1 = String(currentYear_2_1).slice(-2);
        setLabels(['A ' + currentYear_2_string, 'A ' + currentYear_2_1, 'B ' + currentYear_2_string, 'F1.' + currentYear_2_string, 'F2.' + currentYear_2_string]);

    }, [currentYear_2, currentMonth_2, currentTx_2, gbu_2, mois_start]);


    // Handlers - SECTION 2
    const handleFiltreAnneeClick_2 = (item) => {
        setCurrentYear_2(item.nom);
    }
    const handleFiltreMoisClick_2 = (item) => {
        setCurrentMonth_2(item.id);
        setCurrentMonthTitle_2(item.nom);
    }

    const handleFiltreMoisStartClick_2 = (item) => {
        SetMois_start(item.id);
        // setCurrentMonthTitle_2(item.nom);
    }

    const handleFiltreTaux_2 = (item) => {
        let txabyValue = { 'K. DZD': 'KDZD', 'K. € Taux A': 'A', 'K. € Taux B': 'B' };
        setCurrentTx_2(txabyValue[item.nom]);
    }

    const handleGbuFilterClick = (item) => {
        setGbu_2(item.nom);
    }

    const handleExerciceFilterClick = (item, position, cas) => {

        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        let oldStateIndex = checkedState[position];
        if (cas[position] !== 'hidden') {
            saveOldItemCasValue[position] = cas[position];
            setSaveOldItemCasValue(saveOldItemCasValue);
        }
        if (oldStateIndex) {
            const hidden = () => {
                cas[position] = 'hidden';
                setCas(cas);
            }
            hidden();
        } else {
            const oldValue = () => {
                cas[position] = saveOldItemCasValue[position];
                setCas(cas);
            }
            oldValue();
        }
    }

    //SIDEBAR STATE
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);
    const [slidemenu, setSlideMenu] = useState(false);
    const showSlideMenu = () => { setSlideMenu(!slidemenu); setSubnav(false); };

    return (
        (connected ? 
            <div className="dashboard-page">
                <Header />
                <div className="content">
                    <SlideMenu showSlideMenu={showSlideMenu} slidemenu={slidemenu} subnav={subnav} showSubnav={showSubnav} />
                    <div className={slidemenu ? 'corp sub-reduced' : 'corp'}>
                        <section className="bloc" >
                            <div className="row">
                                <div className="title">
                                    <h2>CA détaillé par GBU (YTD)</h2>

                                </div>
                                <div className="filtres double-line">
                                    <div className="line">
                                        {
                                            filtreAnnee.map((item, index) => {
                                                return <DynamicFiltre item={item} key={index} defaultValue={currentYear_2} handleFilterClick={handleFiltreAnneeClick_2} />;
                                            })
                                        }
                                        {/*
                                        filtreMois.map((item, index) => {
                                            return <DynamicFiltreMois item={item} key={index} defaultValue={mois_start} currentYear={currentYear_2} currentYearDefault={currentYearDefault_2} currentMonthDefault={currentMonthDefault_2} handleFilterClick={handleFiltreMoisStartClick_2} />;
                                        })
                                    */}
                                        {
                                            filtreMois.map((item, index) => {
                                                return <DynamicFiltreMois item={item} key={index} defaultValue={currentMonth_2} currentYear={currentYear_2} currentYearDefault={currentYearDefault_2} currentMonthDefault={currentMonthDefault_2} handleFilterClick={handleFiltreMoisClick_2} />;
                                            })
                                        }

                                    </div>

                                    <div className="line">
                                        {
                                            filtreExercice.map((item, index) => {
                                                return <FiltreCheckDynamic item={item} key={index} labels={labels} cas={cas} checkedState={checkedState} handleFilterClick={handleExerciceFilterClick} />;
                                            })
                                        }
                                        {
                                            filtrePaysDetail.map((item, index) => {
                                                return <FiltreChild item={item} key={index} handleFilterClick={handleGbuFilterClick} />;
                                            })
                                        }

                                        {
                                            filtreTaux.map((item, index) => {
                                                return <DynamicFiltreTaux item={item} key={index} currentYear={currentYear_2} defaultValue={currentTx_2} handleFilterClick={handleFiltreTaux_2} />;
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="charte ">
                                <BarChartGeneral exercice={exercice} labels={labels} mois_start={mois_start} currentMonthTitle_2={currentMonthTitle_2} gbu_2={gbu_2} taux={showTxa[currentTx_2]} cas={cas} gabs={gabs} isLoading={isLoading_2} />
                                <div className="download">
                                    <DownloadBtn 
                                        excel={true}
                                        mois_start={mois_start}
                                        currentMonth_2={currentMonth_2}
                                        currentYear_2={currentYear_2}
                                        currentTx_2={currentTx_2}
                                        gbu_2={gbu_2}
                                        canal={canal}
                                        currentMonthTitle_2={currentMonthTitle_2}
                                        startMoiTitle={startMoiTitle}
                                        labels={labels} 
                                    />
                                </div>
                            </div>
                            <div className="center">

                                <Button
                                    children="Explorez les données en détails"
                                    arrow={true} class="btn"
                                    to="/donnees"
                                    donnees={true}
                                    mois_start={mois_start}
                                    currentMonth_2={currentMonth_2}
                                    currentYear_2={currentYear_2}
                                    currentTx_2={currentTx_2}
                                    gbu_2={gbu_2}
                                    canal={canal}
                                    currentMonthTitle_2={currentMonthTitle_2}
                                    startMoiTitle={startMoiTitle}
                                    labels={labels}
                                    prevPage={location.pathname}
                                />
                                <ButtonC children="Vue par mois" arrow={false} class="btn2" to="/ca-gbu-mois" />
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </div>
            :<Navigate to="/login" />)
    );
}

export default CaGbu;