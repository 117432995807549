import React, { useState, useEffect, useRef } from 'react';

const FiltreChild = ({ item, handleFilterClick }) => {
    const [subfiltre, setSubFiltre] = useState(false);
    const showSubFiltre = () => setSubFiltre(!subfiltre);

    const [subfiltreInside, setSubFiltreInside] = useState(false);
    const [subfiltreInside2, setSubFiltreInside2] = useState(false);
    const [icone, setIcone] = useState(false);
    const showSubFiltreInside = () => {setSubFiltreInside(!subfiltreInside); setIcone(!icone); };
    const [icone2, setIcone2] = useState(false);
    const showSubFiltreInside2 = () => {setSubFiltreInside2(!subfiltreInside2); setIcone2(!icone2); };

    const [select, setSelect] = useState('');

    const open = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g transform="translate(-2349.5 -651.5)"><path d="M20,.5H0v-1H20Z" transform="translate(2349.5 661.5)" fill="#665e6f"/><path d="M20,.5H0v-1H20Z" transform="translate(2359.5 651.5) rotate(90)" fill="#665e6f"/></g></svg>;
    const close = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="1" viewBox="0 0 20 1"><g transform="translate(-2349.5 -661)"><path d="M20,.5H0v-1H20Z" transform="translate(2349.5 661.5)" fill="#665e6f"/></g></svg>;

    const componentRef = useRef();

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e) {
            if(componentRef && componentRef.current){
                const ref = componentRef.current
                if(!ref.contains(e.target)){
                    setSubFiltre();
                }
            }
        }
    }, []);

    return (
        <div className="filtre"  ref={componentRef}>
            <div className="filtre-cont" onClick={showSubFiltre} >
                <div className="ico">
                    {item.ico}
                </div>
                <p>{select ? select : item.placeHolder}</p>
                <div className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" height="7" viewBox="0 0 19.225 11.072"><path d="M1323.58,88.116l-9.449-10.233a.5.5,0,1,1,.735-.678l8.717,9.44,8.775-9.441a.5.5,0,1,1,.732.681Z" transform="translate(-1313.999 -77.044)" /></svg>
                </div>
            </div>
            {subfiltre &&
                <div className="subfiltre">
                    <ul>
                        {item.children.map((itemChild) => (
                            <li key={itemChild.id}>
                                { itemChild.children &&
                                    <strong onClick={(e) => { showSubFiltreInside(); }}>
                                        {itemChild.children && icone
                                            ? close
                                            : open
                                        }
                                    </strong>
                                }
                                <span onClick={(e) => { setSelect(itemChild.nom); handleFilterClick(itemChild); showSubFiltre(); }} >
                                    {itemChild.nom}
                                </span>
                                {subfiltreInside && itemChild.children &&
                                    <ul>
                                        {itemChild.children.map((itemSubitemChild) => (
                                            <li key={itemSubitemChild.id} >
                                                { itemSubitemChild.children && 
                                                    <strong onClick={(e) => { showSubFiltreInside2(); }}>
                                                        {itemSubitemChild.children && icone2
                                                            ? close
                                                            : open
                                                        }
                                                    </strong>                                                
                                                }
                                                <span onClick={(e) => { setSelect(itemSubitemChild.nom); handleFilterClick(itemSubitemChild); showSubFiltre(); }} >
                                                    {itemSubitemChild.nom}
                                                </span>
                                                {subfiltreInside2 && itemSubitemChild.children &&
                                                    <ul>
                                                        {itemSubitemChild.children.map((itemSubitemChildLast) => (
                                                            <li key={itemSubitemChildLast.id} >
                                                                <span onClick={(e) => { setSelect(itemSubitemChildLast.nom); handleFilterClick(itemSubitemChildLast); showSubFiltre(); }} >
                                                                    {itemSubitemChildLast.nom}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </div>
    );
};

export default FiltreChild