const filtreFamille = [
    {
        id: 2,
        ico: <svg xmlns="http://www.w3.org/2000/svg" width="17.28" height="19.3" viewBox="0 0 17.28 19.3"><g transform="translate(-956.5 -174)"><g transform="translate(960.482 180.315)"><path d="M416.111,490.726a4.027,4.027,0,0,1-2.765-6.956l5.542-5.23a4.027,4.027,0,1,1,5.528,5.858l-5.543,5.23A4.011,4.011,0,0,1,416.111,490.726Zm5.541-12.685a3.414,3.414,0,0,0-2.352.935l-5.543,5.23a3.427,3.427,0,0,0,4.7,4.985l5.543-5.23a3.427,3.427,0,0,0-2.353-5.92Z" transform="translate(-412.382 -477.741)" fill="#23004c"/><path d="M458.132,521.327a15.04,15.04,0,0,1-4.962-5.537l.54-.261h0a14.7,14.7,0,0,0,4.746,5.293Z" transform="translate(-449.442 -511.968)" fill="#23004c"/></g><g transform="translate(956.5 174)"><g fill="#fff" stroke="#23004c" strokeWidth="0.6"><circle cx="4.5" cy="4.5" r="4.5" stroke="none"/><circle cx="4.5" cy="4.5" r="4.2" fill="none"/></g><path d="M0,5.488,2.744,2.744,5.488,0" transform="translate(1.756 1.756)" fill="none" stroke="#23004c" strokeWidth="0.6"/></g></g></svg>,
        placeHolder: 'Famille',
        children: [
            {
                id: 1,
                bu: "Global",
                nom: "All"
            },
           
        ]
    },
];

export default filtreFamille