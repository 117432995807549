import React, { useState, useEffect, useRef } from 'react';

const Filtre = ({ item, handleFilterClick, selectedValue, dasableBtn }) => {
    const [subfiltre, setSubFiltre] = useState(false);
    const showSubFiltre = () => setSubFiltre(!subfiltre);

    const [select, setSelect] = useState('');

    useEffect(() => {
        setSelect(selectedValue);
    }, [selectedValue]);

    const componentRef = useRef();

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e) {
            if(componentRef && componentRef.current){
                const ref = componentRef.current
                if(!ref.contains(e.target)){
                    setSubFiltre();
                }
            }
        }
    }, []);

    return (
        <div className="filtre" ref={componentRef}>
            <div className="filtre-cont" onClick={showSubFiltre} >
                <div className="ico">
                    {item.ico}
                </div>
                <p>{select ? select : item.placeHolder}</p>
                <div className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" height="7" viewBox="0 0 19.225 11.072"><path d="M1323.58,88.116l-9.449-10.233a.5.5,0,1,1,.735-.678l8.717,9.44,8.775-9.441a.5.5,0,1,1,.732.681Z" transform="translate(-1313.999 -77.044)" /></svg>
                </div>
            </div>
            {subfiltre &&
                <div className="subfiltre">
                    {item.children.map((item) => (
                        (item.nom !== 'Hopital' && dasableBtn) ?
                            <button onClick={(e) => { setSelect(item.nom); handleFilterClick(item); showSubFiltre(); }} key={item.id} disabled>
                                {item.nom}
                            </button>
                            :
                            <button onClick={(e) => { setSelect(item.nom); handleFilterClick(item); showSubFiltre(); }} key={item.id} >
                                {item.nom}
                            </button>
                    ))}
                </div>
            }
        </div>
    );
};

export default Filtre