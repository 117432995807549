const filtreMois = [
    {
        id: 1,
        ico: <svg xmlns="http://www.w3.org/2000/svg" width="17.264" height="18.658" viewBox="0 0 17.264 18.658"><g transform="translate(-399.625 -691.818)"><path d="M-.375-.375H16.889V16H-.375Zm16.514.75H.375V15.254H16.139Z" transform="translate(400 694.472)" nom/><path d="M.375,5.433h-.75V0h.75Z" transform="translate(412.975 691.818)" nom/><path d="M.375,5.433h-.75V0h.75Z" transform="translate(403.834 691.818)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(401.769 701.845)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(405.505 701.845)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(409.24 701.845)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(412.975 701.845)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(401.769 704.794)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(405.505 704.794)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(409.24 704.794)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(412.975 704.794)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(401.769 707.448)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(405.505 707.448)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(409.24 707.448)" nom/><path d="M2.064.375H0v-.75H2.064Z" transform="translate(412.975 707.448)" nom/><path d="M16.514.375H0v-.75H16.514Z" transform="translate(400 699.191)" /></g></svg>,
        placeHolder: 'Mois',
        children: [
            {
                id: 1,
                default: 1,
                nom: "Janvier"
            },
            {
                id: 2,
                default: 2,
                nom: "Février"
            },
            {
                id: 3,
                default: 3,
                nom: "Mars"
            },
            {
                id: 4,
                default: 4,
                nom: "Avril"
            },
            {
                id: 5,
                default: 5,
                nom: "Mai"
            },
            {
                id: 6,
                default: 6,
                nom: "Juin"
            },
            {
                id: 7,
                default: 7,
                nom: "Juillet"
            },
            {
                id: 8,
                default: 8,
                nom: "Août"
            },
            {
                id: 9,
                default: 9,
                nom: "Septembre"
            },
            {
                id: 10,
                default: 10,
                nom: "Octobre"
            },
            {
                id: 11,
                default: 11,
                nom: "Novembre"
            },
            {
                id: 12,
                default: 12,
                nom: "Décembre"
            }
        ]
    },
];

export default filtreMois