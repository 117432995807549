import React, { useReducer } from "react";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('connected', action.payload.connected);
            localStorage.setItem('username', action.payload.username);
            localStorage.setItem('name', action.payload.name);
            localStorage.removeItem('msg');
            //console.log(action.payload)
            return {
                ...state,
            }
        case LOGIN_FAIL:
            localStorage.removeItem('connected', action.payload.connected);
            localStorage.setItem('msg', action.payload.msg);
            //state.msg = action.payload.msg;
            //console.log(action.payload.msg)
            return {
                ...state,
            }
        case LOGOUT:
            localStorage.removeItem('connected');
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('msg');
    }
}