import React, { useState } from 'react'; 
import Header from  '../components/Header';
import Footer from  '../components/Footer';
import SlideMenu from  '../components/SlideMenu';
import Filtre from  '../components/Filtre';
import FiltreCheck from  '../components/FiltreCheck';
import BarChart from '../components/BarChart';
import Button from '../components/Button';
import ButtonC from '../components/ButtonC';
import DownloadBtn from '../components/DownloadBtn';
import '../assets/scss/dashboard.scss';

import filtreMois from '../data/filtreMoisData';
import filtreAnnee from '../data/filtreAnneeData';
import filtreTaux from '../data/filtreTauxData';
import filtreExercice from '../data/filtreExerciceData';
import filtrePays from '../data/filtrePaysData';

const FiltreTemps = () => {

    const [exerciceOption, setExerciceOption] = useState(null);

    const [exercice, setExercice] = useState(['A21', 'A20', 'F2.21', 'F1.21', 'B21']);
    const [labels, setLabels] = useState(['']);
    const [pays, setPays] = useState(['Global', 'GenMed', 'SPC', 'CHC']);
    const [cas, setCas] = useState(['80', '65', '55', '60', '50']);
    const [taux, setTaux] = useState(['K. € Taux A21', 'K. € Taux B22', 'K. DZD']);
    
    const handleBarFilterClick = (item) => {
        const table = cas.sort((a, b) => 0.5 - Math.random());
        setCas([...table]);
    }

    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);
    const [slidemenu, setSlideMenu] = useState(false);
    const showSlideMenu = () => { setSlideMenu(!slidemenu); setSubnav(false); };

    return (
        <div className="dashboard-page">
            <Header />
            <div className="content">
                <SlideMenu showSlideMenu={showSlideMenu} slidemenu={slidemenu} subnav={subnav} showSubnav={showSubnav} />
                <div className={slidemenu ? 'corp sub-reduced': 'corp'}>
                    <section className="bloc" >
                        <div className="row">
                            <div className="title">
                                <h2>Filtre temps / par mois</h2>
                                <h4>Affichage données du mois sélectionné uniquement</h4>
                            </div>
                            <div className="filtres double-line">
                                <div className="line">
                                {
                                    filtreMois.map((item, index) => {
                                        return <Filtre item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                                {
                                    filtreAnnee.map((item, index) => {
                                        return <Filtre item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                                {
                                    filtreTaux.map((item, index) => {
                                        return <Filtre item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                                </div>
                                <div className="line">
                                {
                                    filtreExercice.map((item, index) => {
                                        return <FiltreCheck item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                                {
                                    filtrePays.map((item, index) => {
                                        return <Filtre item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                                </div>
                            </div>
                        </div>
                        <div className="charte">
                            <BarChart exercice={exercice} labels={labels} pays={pays} taux={taux} cas={cas} />
                            <div className="download">
                                <DownloadBtn />
                            </div>
                        </div>
                        <div className="center">
                            <Button children="Explorez les données globales" arrow={true} class="btn" to="/donnees" />
                            <ButtonC children="Vue par mois" arrow={false} class="btn2" to="/filtre-temps" />
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default FiltreTemps;