import React, { useState } from 'react'; 
import Header from  '../components/Header';
import Footer from  '../components/Footer';
import SlideMenu from  '../components/SlideMenu';
import Filtre from  '../components/Filtre';
import '../assets/scss/dashboard.scss';
import PieChart from '../components/PieChart';
import Button from '../components/Button';
import ButtonC from '../components/Button';
import DownloadBtn from '../components/DownloadBtn';

import filtreMois from '../data/filtreMoisData';
import filtreAnnee from '../data/filtreAnneeData';
import filtreTaux from '../data/filtreTauxData';
import filtrePays from '../data/filtrePaysData';

const FiltreGbu = () => {

    const [pieLabel1, setPieLabel1] = useState(['EP', 'Diabète']);
    const [pieColors1, setPieColors1] = useState(['#D278F3','#7A00E6']);
    const [pieCas1, setPieCas1] = useState([3, 9]);

    const [pieLabel2, setPieLabel2] = useState(['Core', 'Foundation']);
    const [pieColors2, setPieColors2] = useState(['#D278F3','#7A00E6']);
    const [pieCas2, setPieCas2] = useState([5, 7]);

    const [pieLabel3, setPieLabel3] = useState(['RD', 'MS', 'Onco', 'Immuno']);
    const [pieColors3, setPieColors3] = useState(['#7A00E6', '#D278F3', '#00C3C1', '#FFC000']);
    const [pieCas3, setPieCas3] = useState([12, 19, 3, 5]);

    const [pieLabel4, setPieLabel4] = useState(['Aspegic', 'Doliprane', 'Others']);
    const [pieColors4, setPieColors4] = useState(['#D278F3', '#00C3C1', '#7A00E6']);
    const [pieCas4, setPieCas4] = useState([3, 2, 7]);
    
    const handleBarFilterClick = (item) => {
        const table1 = pieCas1.sort((a, b) => 0.5 - Math.random());
        setPieCas1([...table1]);

        const table2 = pieCas2.sort((a, b) => 0.5 - Math.random());
        setPieCas2([...table2]);

        const table3 = pieCas3.sort((a, b) => 0.5 - Math.random());
        setPieCas3([...table3]);

        const table4 = pieCas4.sort((a, b) => 0.5 - Math.random());
        setPieCas4([...table4]);
    }

    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);
    const [slidemenu, setSlideMenu] = useState(false);
    const showSlideMenu = () => setSlideMenu(!slidemenu);
    return (
        <div className="dashboard-page">
            <Header />
            <div className="content">
                <SlideMenu showSlideMenu={showSlideMenu} slidemenu={slidemenu} subnav={subnav} showSubnav={showSubnav} />
                <div className={slidemenu ? 'corp sub-reduced': 'corp'}>
                    <section className="bloc" >
                        <div className="row">
                            <div className="title">
                                <h2>Filtre GBU</h2>
                                <h4>Représentation en graphe</h4>
                            </div>
                            <div className="filtres">
                                {
                                    filtrePays.map((item, index) => {
                                        return <Filtre item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                                {
                                    filtreMois.map((item, index) => {
                                        return <Filtre item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                                {
                                    filtreAnnee.map((item, index) => {
                                        return <Filtre item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                                {
                                    filtreTaux.map((item, index) => {
                                        return <Filtre item={item} key={index} handleFilterClick={handleBarFilterClick} />;
                                    })
                                }
                            </div>
                        </div>
                        <div className="double-charte">
                            <div className="charte">
                                <PieChart label={pieLabel1} colors={pieColors1} cas={pieCas1} />
                                <div className="download">
                                    <DownloadBtn />
                                </div>
                                <div className="cta">
                                    <Button children="Explorez les données globales" arrow={true} class="btn btn-small" to="/donnees" />
                                    <ButtonC children="Vue par mois" arrow={false} class="btn2 btn-small" to="/filtre-temps" />
                                </div>
                            </div>
                            <div className="charte">
                                <PieChart label={pieLabel2} colors={pieColors2} cas={pieCas2} />
                                <div className="download">
                                    <DownloadBtn />
                                </div>
                                <div className="cta">
                                    <Button children="Explorez les données globales" arrow={true} class="btn btn-small" to="/donnees" />
                                    <ButtonC children="Vue par mois" arrow={false} class="btn2 btn-small" to="/filtre-temps" />
                                </div>
                            </div>
                            <div className="charte">
                                <PieChart label={pieLabel3} colors={pieColors3} cas={pieCas3} />
                                <div className="download">
                                    <DownloadBtn />
                                </div>
                                <div className="cta">
                                    <Button children="Explorez les données globales" arrow={true} class="btn btn-small" to="/donnees" />
                                    <ButtonC children="Vue par mois" arrow={false} class="btn2 btn-small" to="/filtre-temps" />
                                </div>
                            </div>
                            <div className="charte">
                                <PieChart label={pieLabel4} colors={pieColors4} cas={pieCas4} />
                                <div className="download">
                                    <DownloadBtn />
                                </div>
                                <div className="cta">
                                    <Button children="Explorez les données globales" arrow={true} class="btn btn-small" to="/donnees" />
                                    <ButtonC children="Vue par mois" arrow={false} class="btn2 btn-small" to="/filtre-temps" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default FiltreGbu;