import React from 'react';
import { Link } from 'react-router-dom';

const SubMenu = ({ item, subnav, showSubnav }) => {
  const iconOpened = <svg xmlns="http://www.w3.org/2000/svg" height="7" viewBox="0 0 19.227 11.072"><path d="M1323.58,88.116l-9.449-10.233a.5.5,0,0,1,.735-.678l8.717,9.44,8.775-9.441a.5.5,0,1,1,.732.681Z" transform="translate(1333.225 88.116) rotate(180)" /></svg>;
  const iconClosed = <svg xmlns="http://www.w3.org/2000/svg" height="7" viewBox="0 0 19.225 11.072"><path d="M1323.58,88.116l-9.449-10.233a.5.5,0,1,1,.735-.678l8.717,9.44,8.775-9.441a.5.5,0,1,1,.732.681Z" transform="translate(-1313.999 -77.044)" /></svg>;
  return (
    <li key={item.id}>
      <Link to={item.link} onClick={item.sousmenu && showSubnav}>
        <strong>
          {item.ico}
        </strong>
        <p>{item.name}</p>
        <span>
        { item.sousmenu && subnav
          ? iconOpened
          : item.sousmenu
          ? iconClosed
          : null }
        </span>
      </Link>
      {subnav && item.sousmenu &&
        <>
          <ul className="slide_submenu">
          {item.sousmenu.map((item) => (
              <li key={item.id}>
                  <Link to={item.link} >
                      {item.name}
                  </Link>
              </li>
          ))}
          </ul>
        </>
      }
    </li>
  );
};

export default SubMenu