const filtreProduit = [
    {
        id: 2,
        ico: <svg xmlns="http://www.w3.org/2000/svg" width="16.846" height="16.463" viewBox="0 0 16.846 16.463"><g transform="translate(0.4 0.4)"><path d="M416.986,493.8a5,5,0,0,1-3.434-8.641l6.843-6.458a5,5,0,0,1,6.866,7.277l-6.844,6.458A4.982,4.982,0,0,1,416.986,493.8Zm6.842-15.663a4.186,4.186,0,0,0-2.883,1.146l-6.844,6.458a4.2,4.2,0,1,0,5.768,6.113l6.844-6.458a4.207,4.207,0,0,0,.172-5.941A4.164,4.164,0,0,0,423.828,478.141Z" transform="translate(-412.382 -477.741)" fill="#23004c"/><path d="M459.217,522.683a18.6,18.6,0,0,1-6.137-6.849l.721-.348a18.129,18.129,0,0,0,5.849,6.524Z" transform="translate(-448.504 -511.101)" fill="#23004c"/></g></svg>,
        placeHolder: 'Produit',
        children: [
         
            {
                id: "All",
                nom: "All",
                bu: "Global",
                family: "All"
            }
        ]
    },
];

export default filtreProduit